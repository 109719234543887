import router from '@/router'
import axios from 'axios'
import { toasterError } from '@/helpers'

// Main axios instance
const instance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    'Content-type': 'application/json',
  },
})

function refreshAccessToken() {
  return instance.get('/account/refresh')
}

// Request Interceptor
instance.interceptors.request.use(
  (config: any) => {
    if (config.url === '/account/refresh') {
      config.headers.authorization = `Bearer ${localStorage.getItem('refresh')}`
    } else {
      config.headers.authorization = `Bearer ${localStorage.getItem('access')}`
    }
    return config
  },
  (error: any) => {
    return Promise.reject(error)
  }
)

// Response Interceptor
instance.interceptors.response.use(
  (config: any) => config,
  async (error: any) => {
    if (error.response.data.message === 'The credentials are invalid')
      toasterError(error.response.data.message)

    if (
      error.response.status === 401 &&
      error.response.config.url !== '/account/refresh'
    ) {
      try {
        const response = await refreshAccessToken()
        if (response?.data?.accessToken) {
          localStorage.setItem('access', response.data.accessToken)
          localStorage.setItem('refresh', response.data.refreshToken)

          await instance.request(error.response.config)
        }
      } catch (error) {
        if (error.response.status === 401) {
          setTimeout(() => {
            toasterError(error.response.data.message)
          }, 500)

          localStorage.clear()
          await router.push('/')
        } else {
          toasterError(error.response.data.message)
        }
      }
      return
    }
    if (error.response.data.message === 'Token is invalid') {
      if (error.response.status === 422) {
        localStorage.clear()
        await router.push('/')
      }
      return
    }
    return Promise.reject(error)
  }
)

export default instance
