<script setup>
import useThemeStore from '@/stores/theme'
import AsideMenuLayer from '@/components/theme/AsideMenuLayer.vue'
import OverlayLayer from '@/components/theme/OverlayLayer.vue'

defineProps({
  menu: {
    type: Array,
    required: true,
  },
})

const emit = defineEmits(['menu-click'])

const menuClick = (event, item) => {
  emit('menu-click', event, item)
}
const themeStore = useThemeStore()
</script>

<template>
  <AsideMenuLayer
    :menu="menu"
    :class="[
      themeStore.isAsideMobileExpanded ? 'left-0' : '-left-60 lg:left-0',
      { 'lg:hidden xl:flex': !themeStore.isAsideLgActive },
    ]"
    @menu-click="menuClick"
  />
  <OverlayLayer
    v-show="themeStore.isAsideLgActive"
    z-index="z-30"
    @overlay-click="themeStore.isAsideLgActive = false"
  />
</template>
