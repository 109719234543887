<script setup>
import useThemeStore from '@/stores/theme'

defineProps({
  display: {
    type: String,
    default: 'flex',
  },
  useMargin: Boolean,
})

const themeStore = useThemeStore()
</script>

<template>
  <div
    :class="[
      display,
      themeStore.navBarItemLabelStyle,
      themeStore.navBarItemLabelHoverStyle,
      useMargin ? 'my-2 mx-3' : 'py-2 px-3',
    ]"
    class="items-center cursor-pointer dark:text-white dark:hover:text-slate-400"
  >
    <slot />
  </div>
</template>
