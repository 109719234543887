import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/auth/Login.vue'),
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import('@/views/auth/ChangePassword.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    component: () => import('@/views/users/ProfilePage.vue'),
  },
  {
    path: '/users',
    name: 'UserList',
    component: () => import('@/views/users/UserList.vue'),
  },
  {
    path: '/subscribers',
    name: 'SubscribersList',
    component: () => import('@/views/subscribers/List.vue'),
  },
  {
    path: '/subscribers/history/:userId/',
    name: 'SubscriberView',
    component: () => import('@/views/subscribers/History.vue'),
    props: true,
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('@/views/users/User.vue'),
  },
  {
    path: '/cms',
    name: 'CmsPage',
    component: () => import('@/views/cmsPages/CmsPageList.vue'),
  },
  {
    path: '/cms/:slug',
    name: 'EditCmsPages',
    component: () => import('@/views/cmsPages/EditCmsPages.vue'),
    props: true,
  },
  {
    path: '/paid-calc/strike-price/symmetrical-strategy',
    name: 'StrikePrice',
    component: () => import('@/views/paidCalculator/StrikePrice.vue'),
  },
  {
    path: '/paid-calc/ssc-up-down-categories',
    name: 'SSCUpDownCategories',
    component: () =>
      import(
        '@/views/paidCalculator/scatteredStrategyCalculator/UpDownCategories.vue'
      ),
  },
  {
    path: '/paid-calc/ssc-itm-multipliers',
    name: 'SSCCallMultiplierIMM',
    component: () =>
      import(
        '@/views/paidCalculator/scatteredStrategyCalculator/CallMultiplier.vue'
      ),
  },
  {
    path: '/paid-calc/ssc-otm-multipliers/:type',
    name: 'SSCCallMultiplierOtm',
    component: () =>
      import(
        '@/views/paidCalculator/scatteredStrategyCalculator/CallMultiplierOtm.vue'
      ),
    props: true,
  },
  {
    path: '/paid-calc/ssc-other-settings',
    name: 'SSCOtherSettings',
    component: () =>
      import(
        '@/views/paidCalculator/scatteredStrategyCalculator/OtherSettings.vue'
      ),
  },
  {
    path: '/free-calc/interest-rate',
    name: 'InterestRate',
    component: () =>
      import('@/views/freeCalculator/interestRate/InterestRate.vue'),
  },
  {
    path: '/master/calendar',
    name: 'Calendar',
    component: () => import('@/views/masters/Calendar.vue'),
  },
  {
    path: '/free-calc/equity-options-calculator',
    name: 'EquityOptions',
    component: () => import('@/views/freeCalculator/EquityOptions.vue'),
  },
  {
    path: '/free-calc/currency-exchange-calculator',
    name: 'CurrencyExchange',
    component: () => import('@/views/freeCalculator/CurrencyExchange.vue'),
  },
  {
    path: '/master/settings',
    name: 'Settings',
    component: () => import('@/views/masters/Settings.vue'),
  },
  {
    path: '/login',
    redirect: '/',
  },
]
export default routes
