<script lang="ts">
import { defineComponent, SetupContext } from 'vue'
import ColumnSorting from '@/libraries/DataTable/Components/Table/ColumnSorting.vue'

export default defineComponent({
  name: 'TableHeadCell',
  components: { ColumnSorting },
  props: {
    sortable: {
      type: String,
      required: false,
      default: '',
    },
    sort: {
      type: String,
      required: false,
      default: '',
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['sorting'],

  setup(props, { emit }: SetupContext) {
    const handleClick = (sort) => {
      emit('sorting', sort)
    }

    return {
      handleClick,
    }
  },
})
</script>

<template>
  <th
    scope="col"
    class="dt__table__thead__th dt-text-left dt-text-xs text-gray-700 font-semibold dt-uppercase dt-tracking-wider bg-gray-200"
    :class="{
      'dt-cursor-default': sortable,
      '': !sortable,
    }"
  >
    <ColumnSorting
      v-if="sortable"
      class="dt-px-6 dt-py-3 justify-center"
      :sortable="sortable"
      :sort="sort"
      :multiple="multiple"
      @clicked="handleClick"
    >
      <slot />
    </ColumnSorting>
    <div v-else class="dt-px-2 dt-py-2">
      <slot />
    </div>
  </th>
</template>
