import defaultImage from '@/assets/images/default-profile.svg'
import valorIcon from '@/assets/images/Valor_Inline.svg'

export const imagePreview = () => {
  const metaURL = import.meta.url
  const avatarImages = (val: string | null) => {
    return new URL(val ?? defaultImage, metaURL).href
  }
  const asideLogo = new URL(valorIcon, metaURL).href

  return { avatarImages, asideLogo }
}
