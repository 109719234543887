<script setup lang="ts">
import { storeToRefs } from 'pinia'
import useMainStore from '@/stores'

const { isLoading } = storeToRefs(useMainStore())
</script>

<template>
  <div v-if="isLoading" class="v-loader flex items-center justify-center">
    <div
      class="inline-block h-12 w-12 animate-spin rounded-full border-4 border-current border-r-transparent text-greenish"
    />
  </div>
</template>

<style lang="scss">
.v-loader {
  height: calc(100vh - 250px);
}
</style>
