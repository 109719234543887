import useThemeStore from '@/stores/theme'
import { styleKey } from '@/configs/theme'
import { createPinia } from 'pinia'

const store = createPinia()

/* Init Pinia stores */
const themeStore = useThemeStore(store)

/* App style */
themeStore.setStyle(localStorage[styleKey] ?? 'white')
