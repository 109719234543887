<script setup>
import { ref, computed, watch } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import useThemeStore from '@/stores/theme'
import { mdiMinus, mdiPlus } from '@mdi/js'
import { getButtonColor } from '@/configs/theme/colors'
import BaseIcon from '@/components/theme/BaseIcon.vue'
import AsideMenuList from '@/components/theme/AsideMenuList.vue'

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  isDropdownList: Boolean,
})

const emit = defineEmits(['menu-click'])

const themeStore = useThemeStore()

const route = useRoute()
const isDropdownActive = ref(false)
const routeFullPath = ref(route.fullPath)

watch(
  () => route.fullPath,
  (fullPath) => {
    routeFullPath.value = fullPath
    isDropdownActive.value = hasSubMenu(props.item.menu, fullPath)
  }
)

const hasColor = computed(() => props.item?.color)

const componentClass = computed(() => [
  props.isDropdownList ? 'text-sm' : '',
  hasColor.value
    ? getButtonColor(props.item.color, false, true)
    : `${themeStore.asideMenuItemStyle} dark:text-slate-300 dark:hover:text-white`,
])

const hasDropdown = computed(() => !!props.item.menu)

const hasSubMenu = (menu, routeFullPath) => {
  if (!hasDropdown.value) return false
  return menu.some((item) => {
    if (item?.menu) {
      return hasSubMenu(item.menu, routeFullPath)
    } else {
      return item.to === routeFullPath
    }
  })
}

const menuClick = (event) => {
  emit('menu-click', event, props.item)

  if (hasDropdown.value) {
    isDropdownActive.value = !isDropdownActive.value
  }
}
</script>

<template>
  <li :class="routeFullPath === item.to ? 'active' : 'no-active'">
    <component
      :is="item.to ? RouterLink : 'a'"
      v-slot="vSlot"
      :to="item.to ?? null"
      :href="item.href ?? null"
      :target="item.target ?? null"
      :class="`flex cursor-pointer my-1 hover:text-white hover:bg-primary ${componentClass}`"
      @click="menuClick"
    >
      <BaseIcon
        v-if="item.icon"
        :path="item.icon"
        class="flex-none py-4 px-2"
        :class="{
          'font-bold text-white bg-primary dark:text-white':
            (vSlot && vSlot.isExactActive) || routeFullPath === item.to,
          'text-white bg-primary': isDropdownActive,
        }"
        w="w-8"
        h="h-15"
        :size="18"
      />
      <span
        class="grow text-ellipsis line-clamp-1 py-4"
        :class="[
          { 'pr-0': !hasDropdown },
          {
            'font-bold text-white bg-primary dark:text-white':
              (vSlot && vSlot.isExactActive) || routeFullPath === item.to,
          },
          { 'text-white bg-primary': isDropdownActive },
        ]"
        >{{ item.label }}</span
      >
      <BaseIcon
        v-if="hasDropdown"
        :path="isDropdownActive ? mdiMinus : mdiPlus"
        class="flex-none py-2 px-2"
        :class="{
          'font-bold text-white bg-primary dark:text-white':
            (vSlot && vSlot.isExactActive) || routeFullPath === item.to,
          'text-white bg-primary': isDropdownActive,
        }"
        w="w-12"
        h=""
      />
    </component>
    <AsideMenuList
      v-if="hasDropdown"
      :menu="item.menu"
      :class="
        (themeStore.asideMenuDropdownStyle,
        isDropdownActive ? 'block dark:bg-slate-800/50 bg-gray-200' : 'hidden')
      "
      is-dropdown-list
    />
  </li>
</template>
