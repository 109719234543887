import axios from '@/plugins/axios.plugin'
import { IChangePassword, IAdminDetail, IEditAdmin } from '@/interface'

export const AdminService = () => {
  const getAdmin = (data: IAdminDetail) => {
    return axios.get('/admin/', data)
  }
  const updateAdmin = (data: IEditAdmin) => {
    return axios.put('/admin/', data)
  }
  const changePassword = (password: IChangePassword) => {
    return axios.post('/account/change/password', password)
  }
  return { updateAdmin, changePassword, getAdmin }
}
