import { notify } from 'notiwind'

export const toasterSuccess = (title: String | Object) => {
  return notify(
    {
      group: 'generic',
      title: title,
    },
    4000
  ) // 4s
}

export const toasterError = (title: String = 'Something went wrong!') => {
  return notify(
    {
      group: 'error',
      title: title,
    },
    4000
  ) // 4s
}
