<script setup>
import useMainStore from '@/stores'
import router from '@/router'
import { BaseButton, BaseButtons } from '@/components/theme'

const mainStore = useMainStore()

const handleEvent = (val) => {
  if (val) {
    router.push(val)
  } else router.back()
}
</script>

<template>
  <section class="mb-6 flex items-center justify-between">
    <div class="flex items-center justify-start">
      <h1 class="text-xl leading-tight">
        {{ mainStore.title }}
      </h1>
    </div>
    <BaseButtons v-if="mainStore.label" class="justify-end">
      <BaseButton
        :icon="mainStore.icon"
        :label="mainStore.label"
        :color="mainStore.color"
        :class="mainStore.class"
        small
        @click="handleEvent(mainStore.event)"
      />
    </BaseButtons>
  </section>
</template>
