<script setup>
import { mdiClose } from '@mdi/js'
import {} from 'vue'
import useThemeStore from '@/stores/theme'
import AsideMenuList from '@/components/theme/AsideMenuList.vue'
import BaseIcon from '@/components/theme/BaseIcon.vue'
import { imagePreview } from '@/helpers'

defineProps({
  menu: {
    type: Array,
    required: true,
  },
})
const { asideLogo } = imagePreview()

const emit = defineEmits(['menu-click'])

const themeStore = useThemeStore()

const menuClick = (event, item) => {
  emit('menu-click', event, item)
}
</script>

<template>
  <aside
    id="aside"
    class="w-60 fixed flex z-40 top-0 h-screen transition-position overflow-hidden"
  >
    <div
      :class="themeStore.asideStyle"
      class="flex-1 flex flex-col overflow-hidden dark:bg-slate-900 bg-white"
    >
      <div
        :class="themeStore.asideBrandStyle"
        class="flex flex-row h-14 items-center justify-between dark:bg-slate-900"
      >
        <div
          class="text-center flex-1 lg:text-left lg:pl-6 xl:text-center xl:pl-0"
        >
          <div class="h-6 rounded-lg flex justify-center">
            <img alt="Valor Logo" class="px-5" :src="asideLogo" />
          </div>
        </div>
        <button
          class="hidden lg:inline-block xl:hidden p-3"
          @click.prevent="themeStore.isAsideLgActive = false"
        >
          <BaseIcon :path="mdiClose" />
        </button>
      </div>
      <div
        :class="
          themeStore.darkMode
            ? 'aside-scrollbars-[slate]'
            : themeStore.asideScrollbarsStyle
        "
        class="flex-1 overflow-y-auto overflow-x-hidden"
      >
        <AsideMenuList :menu="menu" @menu-click="menuClick" />
      </div>
    </div>
  </aside>
</template>
