<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SortIcon',

  props: {
    direction: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      default: 'dt-w-[10px] dt-h-[10px] dt-ml-2',
    },
  },
})
</script>

<template>
  <svg
    v-if="direction === 'up'"
    :class="iconClass"
    fill="none"
    viewBox="0 0 8 9"
  >
    <path
      d="M4.23254 8.36922L4.23254 8.6348C4.23254 8.78149 4.11363 8.90039 3.96695 8.90039L0.265588 8.90039C0.118904 8.90039 1.03949e-08 8.78149 2.32184e-08 8.6348L4.64368e-08 8.36922C5.92603e-08 8.22253 0.118904 8.10363 0.265588 8.10363L3.96695 8.10363C4.11363 8.10363 4.23254 8.22253 4.23254 8.36922Z"
      fill="#454545"
    />
    <path
      d="M3.4682 7.10352L0.265588 7.10352C0.118904 7.10352 1.03949e-08 6.98461 2.32184e-08 6.83793L4.64368e-08 6.57234C5.92603e-08 6.42566 0.118904 6.30675 0.265588 6.30675L3.4682 6.30675C3.61489 6.30675 3.73379 6.42566 3.73379 6.57234L3.73379 6.83793C3.73379 6.98461 3.61489 7.10352 3.4682 7.10352Z"
      fill="#454545"
    />
    <path
      d="M2.9694 5.30664L0.265588 5.30664C0.118904 5.30664 1.03949e-08 5.18774 2.32184e-08 5.04105L4.64368e-08 4.77547C5.92603e-08 4.62878 0.118904 4.50988 0.265588 4.50988L2.9694 4.50988C3.11609 4.50988 3.23499 4.62878 3.23499 4.77547L3.23499 5.04105C3.23499 5.18774 3.11609 5.30664 2.9694 5.30664Z"
      fill="#454545"
    />
    <path
      d="M2.47063 3.50977L0.265588 3.50977C0.118904 3.50977 1.03949e-08 3.39086 2.32184e-08 3.24418L4.64368e-08 2.97859C5.92603e-08 2.83191 0.118904 2.713 0.265588 2.713L2.47063 2.713C2.61729 2.713 2.73622 2.83191 2.73622 2.97859L2.73622 3.24418C2.73622 3.39086 2.61731 3.50977 2.47063 3.50977Z"
      fill="#454545"
    />
    <path
      d="M1.97183 1.71289L0.265587 1.71289C0.118903 1.71289 1.03949e-08 1.59399 2.32184e-08 1.4473L4.64368e-08 1.18172C5.92603e-08 1.03503 0.118903 0.916128 0.265587 0.916128L1.97183 0.916128C2.11848 0.916128 2.23741 1.03503 2.23741 1.18172L2.23741 1.4473C2.23741 1.59399 2.11851 1.71289 1.97183 1.71289Z"
      fill="#454545"
    />
    <path
      d="M7.92246 3.23961L7.73467 3.42741C7.68487 3.47721 7.6173 3.5052 7.54687 3.5052C7.47644 3.5052 7.40887 3.47721 7.35905 3.42741L6.44561 2.51398L6.44561 8.50199C6.44561 8.64867 6.32671 8.76758 6.18003 8.76758L5.91444 8.76758C5.76776 8.76758 5.64885 8.64867 5.64885 8.50199L5.64885 2.514L4.73542 3.42744C4.68559 3.47723 4.61806 3.50523 4.54762 3.50523C4.47719 3.50523 4.40962 3.47723 4.35983 3.42744L4.172 3.23964C4.06832 3.13593 4.06832 2.96776 4.172 2.86405L5.85944 1.17661C5.91131 1.12474 5.97927 1.09882 6.04723 1.09882C6.1152 1.09882 6.18319 1.12474 6.23503 1.17661L7.92246 2.86405C8.02618 2.96776 8.02618 3.13593 7.92246 3.23961Z"
      fill="#454545"
    />
  </svg>
  <svg
    v-else-if="direction === 'down'"
    :class="iconClass"
    fill="none"
    viewBox="0 0 8 9"
  >
    <path
      d="M4.23254 0.630784V0.365197C4.23254 0.218513 4.11363 0.0996094 3.96695 0.0996094H0.265588C0.118904 0.0996094 0 0.218513 0 0.365197V0.630784C0 0.777468 0.118904 0.896372 0.265588 0.896372H3.96695C4.11363 0.896372 4.23254 0.777468 4.23254 0.630784Z"
      fill="#454545"
    />
    <path
      d="M3.4682 1.89648H0.265588C0.118904 1.89648 0 2.01539 0 2.16207V2.42766C0 2.57434 0.118904 2.69325 0.265588 2.69325H3.4682C3.61489 2.69325 3.73379 2.57434 3.73379 2.42766V2.16207C3.73379 2.01539 3.61489 1.89648 3.4682 1.89648Z"
      fill="#454545"
    />
    <path
      d="M2.9694 3.69336H0.265588C0.118904 3.69336 0 3.81226 0 3.95895V4.22453C0 4.37122 0.118904 4.49012 0.265588 4.49012H2.9694C3.11609 4.49012 3.23499 4.37122 3.23499 4.22453V3.95895C3.23499 3.81226 3.11609 3.69336 2.9694 3.69336Z"
      fill="#454545"
    />
    <path
      d="M2.47063 5.49023H0.265588C0.118904 5.49023 0 5.60914 0 5.75582V6.02141C0 6.16809 0.118904 6.287 0.265588 6.287H2.47063C2.61729 6.287 2.73622 6.16809 2.73622 6.02141V5.75582C2.73622 5.60914 2.61731 5.49023 2.47063 5.49023Z"
      fill="#454545"
    />
    <path
      d="M1.97183 7.28711H0.265587C0.118903 7.28711 0 7.40601 0 7.5527V7.81828C0 7.96497 0.118903 8.08387 0.265587 8.08387H1.97183C2.11848 8.08387 2.23741 7.96497 2.23741 7.81828V7.5527C2.23741 7.40601 2.11851 7.28711 1.97183 7.28711Z"
      fill="#454545"
    />
    <path
      d="M7.92246 5.76039L7.73467 5.57259C7.68487 5.52279 7.6173 5.4948 7.54687 5.4948C7.47644 5.4948 7.40887 5.52279 7.35905 5.57259L6.44561 6.48602V0.498009C6.44561 0.351325 6.32671 0.232422 6.18003 0.232422H5.91444C5.76776 0.232422 5.64885 0.351325 5.64885 0.498009V6.486L4.73542 5.57256C4.68559 5.52277 4.61806 5.49477 4.54762 5.49477C4.47719 5.49477 4.40962 5.52277 4.35983 5.57256L4.172 5.76036C4.06832 5.86407 4.06832 6.03224 4.172 6.13595L5.85944 7.82339C5.91131 7.87526 5.97927 7.90118 6.04723 7.90118C6.1152 7.90118 6.18319 7.87526 6.23503 7.82339L7.92246 6.13595C8.02618 6.03224 8.02618 5.86407 7.92246 5.76039Z"
      fill="#454545"
    />
  </svg>
  <svg v-else :class="iconClass" fill="#454545" viewBox="0 0 8 8">
    <g clip-path="url(#clip0)">
      <path
        d="M4.23254 0.630296V0.364709C4.23254 0.218025 4.11363 0.0991211 3.96695 0.0991211H0.265587C0.118904 0.0991211 0 0.218025 0 0.364709V0.630296C0 0.77698 0.118904 0.895884 0.265587 0.895884H3.96695C4.11363 0.895884 4.23254 0.77698 4.23254 0.630296Z"
      />
      <path
        d="M4.23254 7.2657V7.53129C4.23254 7.67797 4.11363 7.79688 3.96695 7.79688H0.265587C0.118904 7.79688 0 7.67797 0 7.53129V7.2657C0 7.11902 0.118904 7.00011 0.265587 7.00011H3.96695C4.11363 7.00011 4.23254 7.11902 4.23254 7.2657Z"
      />
      <path
        d="M2.9694 3.69287H0.265588C0.118904 3.69287 0 3.81177 0 3.95846V4.22405C0 4.37073 0.118904 4.48963 0.265588 4.48963H2.9694C3.11609 4.48963 3.23499 4.37073 3.23499 4.22405V3.95846C3.23499 3.81177 3.11609 3.69287 2.9694 3.69287Z"
      />
      <path
        d="M7.92222 5.7599L7.73442 5.5721C7.68462 5.5223 7.61706 5.49431 7.54663 5.49431C7.47619 5.49431 7.40863 5.5223 7.3588 5.5721L6.44537 6.48554L6.5 4.5L6 4.5C5.85332 4.5 5.5 4.5 5.5 4.5L5.64861 6.48551L4.73517 5.57207C4.68535 5.52228 4.61781 5.49428 4.54738 5.49428C4.47694 5.49428 4.40938 5.52228 4.35958 5.57207L4.17176 5.75987C4.06807 5.86358 4.06807 6.03175 4.17176 6.13547L5.85919 7.8229C5.91106 7.87477 5.97902 7.90069 6.04699 7.90069C6.11495 7.90069 6.18294 7.87477 6.23479 7.8229L7.92222 6.13547C8.02593 6.03175 8.02593 5.86358 7.92222 5.7599Z"
      />
      <path
        d="M4.07778 2.14074L4.26558 2.32853C4.31538 2.37833 4.38294 2.40632 4.45337 2.40632C4.52381 2.40632 4.59137 2.37833 4.6412 2.32853L5.55463 1.4151L5.5 3.40063L6 3.40063C6.14668 3.40063 6.5 3.40063 6.5 3.40063L6.35139 1.41513L7.26483 2.32856C7.31465 2.37836 7.38219 2.40635 7.45262 2.40635C7.52306 2.40635 7.59062 2.37836 7.64042 2.32856L7.82824 2.14076C7.93193 2.03705 7.93193 1.86888 7.82824 1.76517L6.14081 0.0777329C6.08894 0.0258636 6.02098 -5.78621e-05 5.95301 -5.7868e-05C5.88505 -5.7874e-05 5.81706 0.0258636 5.76521 0.0777329L4.07778 1.76517C3.97407 1.86888 3.97407 2.03705 4.07778 2.14074Z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect fill="white" height="8" width="8" />
      </clipPath>
    </defs>
  </svg>
</template>
