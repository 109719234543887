<script setup lang="ts">
import { computed } from 'vue'
import { get } from '@/helpers'
import LayoutAuthenticated from '@/layouts/LayoutAuthenticated.vue'
import { SectionMain, SectionTitleLineWithButton } from '@/components/theme'
import { Loader } from '@/components/common'
import { useRouter } from 'vue-router'

const token = get('access')
const router = useRouter()

const hasToken = computed(() => {
  return token // Check if token exists
})

const isLoginRoute = computed(() => {
  return router.currentRoute.value.name === 'Login'
})
</script>

<template>
  <div>
    <template v-if="isLoginRoute">
      <router-view />
    </template>
    <template v-else-if="hasToken">
      <LayoutAuthenticated>
        <SectionMain>
          <SectionTitleLineWithButton />
          <Loader />
          <router-view />
        </SectionMain>
      </LayoutAuthenticated>
    </template>
  </div>
</template>
