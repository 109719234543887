import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export const formatDate = (value: any, format = 'YYYY-MM-DD') => {
  return dayjs(value).format(format)
}

export const calcDateLimit = () => {
  return {
    upperDateFutures: new Date().setFullYear(new Date().getFullYear() + 1),
    upperDateOptions: new Date().setMonth(new Date().getMonth() + 2),
  }
}

export const isWeekendDay = (date: Date) => {
  return [0, 6].indexOf(new Date(date).getDay()) != -1
}

export const dateUTCFormat = (value: any, datesFormat: any) => {
  if (datesFormat) {
    return dayjs(value, datesFormat).utc().format()
  } else {
    return dayjs(value).utc().format()
  }
}

export const dateUTCToLocal = (value: any, format = 'YYYY-MM-DD') => {
  if (value) {
    return dayjs.utc(value).local().format(format)
  }
}

export const ageRestrict = (value: any) => {
  const userDate = dayjs(value)
  const today = dayjs()
  if (today.diff(userDate, 'years') < 16) {
    return false
  }
  return true
}

export const ShowDatesFormat = (value: any, format = 'DD-MM-YYYY') => {
  return dayjs.utc(value).local().format(format)
}

export const tomorrowDate = () => dayjs().add(1, 'day').toDate()
