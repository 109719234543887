import {
  mdiAccountGroup,
  mdiMonitor,
  mdiCurrencyUsd,
  mdiAccountCogOutline,
  mdiAccountDetailsOutline,
  mdiFinance,
  mdiMargin,
  mdiCurrencyRupee,
  mdiAccountCashOutline,
  mdiCalendarBlankOutline,
  mdiArrowUpDown,
  mdiCalculatorVariantOutline,
  mdiTableMultiple,
  mdiCogOutline,
  mdiCog,
  mdiCallMade,
  mdiCallReceived,
} from '@mdi/js'

export default [
  {
    to: '/dashboard',
    icon: mdiMonitor,
    label: 'Dashboard',
  },
  {
    to: '/users',
    icon: mdiAccountGroup,
    label: 'Users',
  },
  {
    to: '/subscribers',
    icon: mdiAccountCashOutline,
    label: 'Subscribers',
  },
  {
    to: '/cms',
    icon: mdiAccountCogOutline,
    label: 'CMS Pages',
  },

  {
    icon: mdiCalculatorVariantOutline,
    label: 'Free Calculator',
    menu: [
      {
        to: '/free-calc/interest-rate',
        label: 'Interest Rate',
        icon: mdiMargin,
      },
      {
        to: '/free-calc/equity-options-calculator',
        label: 'Equity Options',
        icon: mdiFinance,
      },
      {
        to: '/free-calc/currency-exchange-calculator',
        label: 'Currency Exchange',
        icon: mdiCurrencyUsd,
      },
    ],
  },
  {
    icon: mdiCalculatorVariantOutline,
    label: 'Paid Calculator',
    menu: [
      {
        to: '/paid-calc/strike-price/symmetrical-strategy',
        label: 'Strike Price',
        icon: mdiCurrencyRupee,
      },
      {
        to: '/paid-calc/ssc-up-down-categories',
        label: 'SSC - Up Down Categories',
        icon: mdiArrowUpDown,
      },
      {
        to: '/paid-calc/ssc-itm-multipliers',
        label: 'SSC ITM Multipliers',
        icon: mdiTableMultiple,
      },
      {
        to: '/paid-calc/ssc-otm-multipliers/bullish',
        label: 'SSC OTM Multipliers Bullish',
        icon: mdiCallMade,
      },
      {
        to: '/paid-calc/ssc-otm-multipliers/bearish',
        label: 'SSC OTM Multipliers Bearish',
        icon: mdiCallReceived,
      },
      {
        to: '/paid-calc/ssc-other-settings',
        label: 'SSC - Other Settings',
        icon: mdiCogOutline,
      },
    ],
  },
  {
    icon: mdiAccountDetailsOutline,
    label: 'Masters',
    menu: [
      {
        to: '/master/calendar',
        label: 'Calendar',
        icon: mdiCalendarBlankOutline,
      },
      {
        to: '/master/settings',
        label: 'Settings',
        icon: mdiCog,
      },
    ],
  },
]
