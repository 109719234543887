<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
  ref,
  SetupContext,
  watch,
  onUpdated,
} from 'vue'
import { PaginationProps } from '@/libraries/DataTable/@types/PaginationProps'
import { QueryProps } from '@/libraries/DataTable/@types/QueryProps'
import DTFilter from '@/libraries/DataTable/Components/Filter/Filter.vue'
import Loading from '@/libraries/DataTable/Components/Loading.vue'
import {
  BottomPaginationWrapper,
  Pagination,
  PaginationSize,
  TopPaginationWrapper,
} from '@/libraries/DataTable/Components/Pagination'
import TableBodyCell from '@/libraries/DataTable/Components/Table/TableBodyCell.vue'
import TableHeadCell from '@/libraries/DataTable/Components/Table/TableHeadCell.vue'
import TableRow from '@/libraries/DataTable/Components/Table/TableRow.vue'
import TableWrapper from '@/libraries/DataTable/Components/Table/TableWrapper.vue'
import TBody from '@/libraries/DataTable/Components/Table/TBody.vue'
import THead from '@/libraries/DataTable/Components/Table/THead.vue'
import { debounce, formatString } from '@/libraries/DataTable/utils/helpers'

const PER_PAGE = 10

const PER_PAGE_OPTIONS = [10, 20, 50, 100]

const DataTable = defineComponent({
  name: 'DataTable',

  components: {
    TableHeadCell,
    TableBodyCell,
    TBody,
    TableRow,
    THead,
    BottomPaginationWrapper,
    TableWrapper,
    PaginationSize,
    TopPaginationWrapper,
    DTFilter,
    Loading,
    Pagination,
  },

  props: {
    rows: { type: null, required: true },
    columns: { type: Object, required: false, default: null },
    pagination: {
      type: Object as PropType<PaginationProps>,
      required: false,
      default: null,
    },
    rounded: { type: Boolean, required: false, default: false },
    striped: { type: Boolean, required: false, default: false },
    sn: { type: Boolean, required: false, default: false },
    filter: { type: Boolean, required: false, default: false },
    loading: { type: Boolean, required: false, default: false },
    perPageOptions: {
      type: Array as PropType<Array<string | number>>,
      required: false,
      default: () => PER_PAGE_OPTIONS,
    },
    query: {
      type: Object as PropType<QueryProps>,
      required: false,
      default: () => ({}),
    },
    topPagination: { type: Boolean, required: false, default: false },
    bottomPagination: { type: Boolean, required: false, default: true },
    hoverable: { type: Boolean, required: false, default: false },
    nonClickable: { type: Boolean, required: false, default: false },
    sortable: { type: Boolean, required: false, default: false },
    paginationResetEvent: { type: Boolean, required: false, default: false },
  },

  emits: ['loadData', 'rowClicked', 'handlePageChange'],

  setup(props, { emit }: SetupContext) {
    const tableQuery = ref<any>({
      page: props.pagination?.page || 1,
      search: props.query?.search || '',
      per_page: props.pagination?.per_page || PER_PAGE,
      sort: props.query?.sort || '',
    })

    onUpdated(() => {
      if (props.paginationResetEvent) {
        handlePageChange(Number(props.pagination.page))
        tableQuery.value.per_page = 10
      }
    })

    const showPagination = computed(() => !!props.pagination)
    const totalData = computed(
      () => props.pagination?.total || props.rows.length
    )
    const tableRows = computed(() => props.rows)

    const tableColumns = computed(() => {
      if (props.columns) {
        return props.columns
      }

      if (props.rows.length === 0) {
        return {}
      }

      return Object.keys(props.rows[0]).reduce(
        (cols, key) => ({ ...cols, [key]: formatString(key) }),
        {}
      )
    })

    const paginatedRowIndex = computed(() =>
      showPagination.value
        ? tableQuery.value.per_page * (tableQuery.value.page - 1)
        : 0
    )

    const uniqueId = () => Math.floor(Math.random() * 100)

    const fireDataLoad = () => {
      emit('loadData', tableQuery.value)
    }

    watch(
      () => ({ ...tableQuery.value }),
      () => {
        fireDataLoad()
      },
      {
        deep: true,
        immediate: true,
      }
    )

    const handlePageChange = (page: number) => {
      tableQuery.value.page = page
      emit('handlePageChange', { page })
      window.scrollTo(0, 0)
    }

    const handleOnSearchChange = debounce((value) => {
      tableQuery.value = { ...tableQuery.value, page: 1, search: value }
    })

    const handleOnPaginationSizeChange = (value: string) => {
      tableQuery.value = { ...tableQuery.value, page: 1, per_page: value }
      emit('handlePageChange', { limit: value })
      window.scrollTo(0, 0)
    }

    const handleSorting = (value: string) => {
      tableQuery.value.sort = value
    }

    const rowClickHandler = (row: any) => {
      if (props.nonClickable || !props.hoverable) {
        return
      }

      emit('rowClicked', row)
    }

    return {
      tableQuery,
      showPagination,
      totalData,
      tableRows,
      tableColumns,
      paginatedRowIndex,
      uniqueId,
      handlePageChange,
      handleOnSearchChange,
      handleOnPaginationSizeChange,
      rowClickHandler,
      handleSorting,
    }
  },
})

export default DataTable
</script>

<template>
  <div class="data-table dt-flex dt-flex-col">
    <div class="dt-align-middle dt-min-w-full">
      <DTFilter
        v-if="filter && topPagination"
        :search="tableQuery.search"
        @input="handleOnSearchChange"
      />

      <div
        class="dt__wrapper dt-relative"
        :class="{ 'sm:dt-rounded-lg': rounded }"
      >
        <slot v-if="loading" name="loading">
          <Loading />
        </slot>

        <TopPaginationWrapper
          v-if="showPagination"
          :with-pagination="topPagination"
        >
          <Pagination
            v-if="topPagination"
            class="dt-flex-1 dt-pr-4"
            :total="totalData"
            :current-page="tableQuery.page"
            :per-page="parseInt(tableQuery.per_page.toString())"
            @changed="handlePageChange"
          >
            <template #pagination-info="paginationInfo">
              <slot
                name="pagination-info"
                :start="paginationInfo.start"
                :end="paginationInfo.end"
                :total="paginationInfo.total"
              >
                Showing
                <span class="dt-font-medium" v-text="paginationInfo.start" />
                to
                <span class="dt-font-medium" v-text="paginationInfo.end" />
                of
                <span class="dt-font-medium" v-text="paginationInfo.total" />
                results.
              </slot>
            </template>
          </Pagination>
        </TopPaginationWrapper>

        <TableWrapper>
          <THead>
            <slot v-if="sn" name="thead-sn">
              <TableHeadCell
                class="dt__table__thead__th_sn text-center"
                v-text="`S.N.`"
              />
            </slot>

            <slot
              name="thead"
              :column="tableColumns"
              :sorting="handleSorting"
              :sort="query.sort ?? ''"
            >
              <TableHeadCell
                v-for="(label, key) in tableColumns"
                :key="`datatable-thead-th-${key}`"
                :sortable="sortable ? key.toString() : ''"
                :sort="query.sort ?? ''"
                @sorting="handleSorting"
              >
                {{ label }}
              </TableHeadCell>
            </slot>
          </THead>

          <TBody>
            <TableRow
              v-for="(row, rowIndex) in tableRows"
              :key="`datatable-row-${uniqueId()}-${rowIndex}`"
              :hoverable="hoverable"
              :non-clickable="nonClickable"
              :row-index="rowIndex"
              :striped="striped"
              @clicked="rowClickHandler(row)"
            >
              <slot v-if="sn" name="tbody-sn" :sn="rowIndex + 1">
                <TableBodyCell
                  class="dt__table__tbody_td_sn"
                  v-text="rowIndex + 1 + paginatedRowIndex"
                />
              </slot>

              <slot name="tbody" :index="rowIndex" :row="row">
                <TableBodyCell
                  v-for="(label, key) in tableColumns"
                  :key="`datatable-tbody-td-${uniqueId()}-${key}`"
                  :name="label"
                  v-text="row[key]"
                />
              </slot>
            </TableRow>

            <TableRow v-if="tableRows.length === 0" :row-index="0">
              <slot name="empty" />
            </TableRow>
          </TBody>
        </TableWrapper>
        <BottomPaginationWrapper
          v-if="
            showPagination &&
            bottomPagination &&
            !loading &&
            tableRows.length > 0
          "
        >
          <div class="justify-end flex">
            <pagination
              :total="totalData"
              :current-page="tableQuery.page"
              :per-page="parseInt(tableQuery.per_page.toString())"
              @changed="handlePageChange"
            >
              <template #pagination-info="paginationInfo">
                <slot
                  name="pagination-info"
                  :start="paginationInfo.start"
                  :end="paginationInfo.end"
                  :total="paginationInfo.total"
                >
                  Showing
                  <span class="dt-font-medium" v-text="paginationInfo.start" />
                  to
                  <span class="dt-font-medium" v-text="paginationInfo.end" />
                  of
                  <span class="dt-font-medium" v-text="paginationInfo.total" />
                  results.
                </slot>
              </template>
            </pagination>
            <PaginationSize
              :value="tableQuery.per_page"
              :options="perPageOptions"
              @input="handleOnPaginationSizeChange"
            />
          </div>
        </BottomPaginationWrapper>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./DataTable.scss" />
