import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/router/router'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: () => {
    // always scroll to top
    window.scrollTo(0, 0)
  },
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('access')
  // If logged in, or going to the Login page.
  if (token && to.name === 'Login') {
    next({ name: 'Dashboard' })
  }
  if (token || to.name === 'Login') {
    // Continue to page.
    next()
  } else {
    // Not logged in, redirect to login.
    next({ name: 'Login' })
  }
})

export default router
