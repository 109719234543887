import { ref } from 'vue'
import { mdiAccountMultiple, mdiAccountCheck, mdiAccountBox } from '@mdi/js'

export const cardDatas = ref({
  user: {
    color: 'text-greenish',
    icon: mdiAccountBox,
    number: 0,
    suffix: '',
    label: 'Total Users',
  },
  subscriber: {
    color: 'text-fadeorg',
    icon: mdiAccountMultiple,
    number: 0,
    suffix: '',
    label: 'Total Subscribers',
  },
  rate: {
    color: 'text-dark',
    icon: mdiAccountCheck,
    number: 0,
    suffix: '%',
    label: 'Conversion Rate',
  },
})
