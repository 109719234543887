import { defineRule, configure } from 'vee-validate'
import { localize } from '@vee-validate/i18n'
import {
  required,
  alpha,
  numeric,
  length,
  email,
  confirmed,
  min,
  digits,
  min_value,
} from '@vee-validate/rules'
import dayjs from 'dayjs'

defineRule('required', required)
defineRule('name_required', required)
defineRule('number_required', required)
defineRule('confirm_required', required)
defineRule('date_required', required)
defineRule('select_required', required)
defineRule('email_required', required)
defineRule('password_required', required)
defineRule('email', email)
defineRule('alpha', alpha)
defineRule('numeric', numeric)
defineRule('length', length)
defineRule('pin_length', digits)
defineRule('confirmed', confirmed)
defineRule('min', min)
defineRule('min_password', min)
defineRule('valid_phone', length)
defineRule('min_value', min_value)

defineRule('regex_password', (value: string) => {
  // Check if valid password
  // eslint-disable-next-line no-useless-escape
  if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%\^&\*])/.test(value)) {
    return 'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character'
  }
  return true
})

defineRule('ageRestrict', (value: any) => {
  const userDate = dayjs(value)
  const today = dayjs()
  if (today.diff(userDate, 'years') < 16) {
    return false
  }
  return true
})

defineRule('decimal', (value: string) => {
  if (!/^[0-9]\d{0,9}(\.\d{1,5})?%?$/.test(value)) {
    return 'enter numeric or decimal value only'
  }
  return true
})

defineRule('negative_decimal', (value: string) => {
  // eslint-disable-next-line no-useless-escape
  if (!/^-?\d*(\.\d{1,5})?$/.test(value)) {
    return false
  }
  return true
})

defineRule('restrict_negative', (value: string) => {
  if (/^-?\d+(\.\d+)?$/.test(value)) {
    return false
  }
  return true
})

defineRule('restrict_zero', (value: string) => {
  if (/^-?0*(\.0+)?$/.test(value)) {
    // check zero with decimal and negative
    return false
  }
  return true
})

defineRule('negative_with_2decimal_100', (value: string) => {
  // eslint-disable-next-line no-useless-escape
  if (!/^(?!00?\.00$)((100)|(\d{1,2}(\.\d{1,2})?))$/.test(value)) {
    return false
  }
  return true
})

defineRule('negative_with_2decimal', (value: string) => {
  // eslint-disable-next-line no-useless-escape
  if (!/^(?!00?\.00$)((100)|(\d*(\.\d{1,2})?))$/.test(value)) {
    return false
  }
  return true
})

defineRule('negative_3decimal', (value: string) => {
  // eslint-disable-next-line no-useless-escape
  if (!/^-?\d*(\.\d{1,3})?$/.test(value)) {
    return false
  }
  return true
})

defineRule('no_decimal', (value: string) => {
  // eslint-disable-next-line no-useless-escape
  if (!/^-?\d*$/.test(value)) {
    return false
  }
  return true
})

configure({
  validateOnBlur: true,
  validateOnChange: true,
  validateOnInput: false,
  validateOnModelUpdate: true,

  // Generates an English message locale generator
  generateMessage: localize('en', {
    messages: {
      required: 'Enter {field}.',
      password_required: 'Enter password.',
      confirm_required: 'Enter {field} password.',
      name_required: 'Enter {field}.',
      number_required: 'Enter {field} digit no.',
      date_required: 'Select {field}.',
      select_required: 'Select {field}.',
      email_required: 'Enter {field} address.',
      alpha: 'Enter alphabet characters only.',
      email: 'The {field} should be valid {field}.',
      numeric: 'Enter numeric value only.',
      length: '{field} must be of 0:{min} digits.',
      pin_length: '{field} code must be 6 digits.',
      confirmed: 'Entered password do not match with previous password field.',
      min: '{field} length must be 0:{min} characters.',
      regex_password:
        'Password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.',
      min_password: 'password must be atleast 0:{min} characters.',
      valid_phone: 'Enter valid mobile no.',
      vselect: 'Please select {field}.',
      min_value: '{field} must be greater than 150.',
      ageRestrict: 'User is not 16+ of age.',
    },
  }),
})
