import { defineStore } from 'pinia'
import * as styles from '@/configs/theme'
import { darkModeKey, styleKey } from '@/configs/theme'

const useThemeStore = defineStore('init-store', {
  state: (): any => {
    return {
      isAsideMobileExpanded: false,
      isAsideLgActive: false,

      /* User */
      userName: null,
      userEmail: null,
      userAvatar: null,

      /* Field focus with ctrl+k (to register only once) */
      isFieldFocusRegistered: false,

      /* Styles */
      asideStyle: '',
      asideBrandStyle: '',
      asideMenuItemStyle: '',
      asideMenuItemActiveStyle: '',
      asideMenuDropdownStyle: '',
      navBarItemLabelStyle: '',
      navBarItemLabelHoverStyle: '',
      navBarItemLabelActiveColorStyle: '',
      overlayStyle: '',

      /* Dark mode */
      darkMode: false,
    }
  },

  getters: {},

  actions: {
    asideMobileToggle() {
      this.isAsideMobileExpanded = !this.isAsideMobileExpanded
    },
    setUser(payload: any) {
      if (payload.name) {
        this.userName = payload.name
      }
      if (payload.email) {
        this.userEmail = payload.email
      }
      if (payload.avatar) {
        this.userAvatar = payload.avatar
      }
    },

    setStyle(payload: any) {
      if (!styles[payload]) {
        return
      }

      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(styleKey, payload)
      }

      const style = styles[payload]

      for (const key in style) {
        this[`${key}Style`] = style[key]
      }
    },

    setDarkMode(payload = null) {
      this.darkMode = payload !== null ? payload : !this.darkMode

      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(darkModeKey, this.darkMode ? '1' : '0')
      }

      if (typeof document !== 'undefined') {
        document.body.classList[this.darkMode ? 'add' : 'remove'](
          'dark-scrollbars'
        )

        document.documentElement.classList[this.darkMode ? 'add' : 'remove'](
          'dark-scrollbars-compat'
        )
      }
    },
  },
})

export default useThemeStore
