import { defineStore } from 'pinia'

const useMainStore = defineStore('main', {
  state: () => ({
    isLoading: true,
    title: '',
    label: '',
    color: '',
    icon: '',
    class: '',
    event: '',
  }),
})

export default useMainStore
