<template>
  <div class="dt__filter dt-mb-3 dt-w-full"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DTFilter',

  props: {
    search: { type: String, required: true },
  },

  emits: ['input'],
})
</script>
