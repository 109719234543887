<script setup lang="ts">
import { mdiForwardburger, mdiBackburger, mdiMenu } from '@mdi/js'
import { useRouter } from 'vue-router'
import { reactive, onMounted } from 'vue'
import useThemeStore from '@/stores/theme'
import { clearAll } from '@/helpers'
import {
  menuAside,
  menuNavBar,
  BaseIcon,
  NavBar,
  NavBarItemPlain,
  AsideMenu,
} from '@/components/theme'
import Notification from '@/components/common/Notification.vue'
import useMainStore from '@/stores'
import { AdminService } from '@/services/apis'

onMounted(async () => {
  await getAdminData()
})

const adminDetails: any = reactive({
  firstName: '',
})
const { getAdmin } = AdminService()

const mainStore = useMainStore()

const getAdminData = async () => {
  const adminData = await getAdmin(adminDetails)
  if (adminData && adminData.data) {
    useThemeStore().setUser({
      name: (adminDetails.firstName = adminData.data.firstName),
    })
  }
}

const layoutAsidePadding = 'xl:pl-60'

const themeStore = useThemeStore()

const router = useRouter()

router.beforeEach(() => {
  themeStore.isAsideMobileExpanded = false
  themeStore.isAsideLgActive = false
  mainStore.$patch({
    isLoading: false,
    title: '',
    label: '',
    color: '',
    icon: '',
    event: '',
  })
})

const menuClick = (event: PointerEvent, item: any) => {
  if (item.isToggleLightDark) {
    themeStore.setDarkMode()
  }

  if (event && item.isLogout) {
    clearAll()
    router.push('/')
  }
}
</script>

<template>
  <div
    class="text-default lg:text-sm md:text-sm sm:text-xs"
    :class="{
      dark: themeStore.darkMode,
      'overflow-hidden lg:overflow-visible': themeStore.isAsideMobileExpanded,
    }"
  >
    <div
      :class="[
        layoutAsidePadding,
        { 'ml-60 lg:ml-0': themeStore.isAsideMobileExpanded },
      ]"
      class="mt-14 min-h-screen w-screen transition-position lg:w-auto bg-gray-100 dark:bg-slate-800 dark:text-slate-100"
    >
      <NavBar
        :menu="menuNavBar"
        :class="[
          layoutAsidePadding,
          { 'ml-60 lg:ml-0': themeStore.isAsideMobileExpanded },
        ]"
        @menu-click="menuClick"
      >
        <NavBarItemPlain
          display="flex lg:hidden"
          @click.prevent="themeStore.asideMobileToggle()"
        >
          <BaseIcon
            :path="
              themeStore.isAsideMobileExpanded
                ? mdiBackburger
                : mdiForwardburger
            "
            size="24"
          />
        </NavBarItemPlain>
        <NavBarItemPlain
          display="hidden lg:flex xl:hidden"
          @click.prevent="themeStore.isAsideLgActive = true"
        >
          <BaseIcon :path="mdiMenu" size="24" />
        </NavBarItemPlain>
      </NavBar>
      <AsideMenu :menu="menuAside" @menu-click="menuClick" />
      <slot />

      <Notification />
      <!-- <FooterBar>
        Get more with
        <a
          href="https://tailwind-vue.justboil.me/"
          target="_blank"
          class="text-blue-600"
          >Premium version</a
        >
      </FooterBar> -->
    </div>
  </div>
</template>
